import React from 'react'

export const ServiceSubheadingContainer = (props) => {
  return (
    <>
    <div className="ServiceSubHeadingContainer">
      <h3>{props.text}</h3>
    </div>
  </>
  )
}
