import React from 'react'

export const PageNotFound = () => {
  return (
    <div>
      
      <h2 className='text-center p-5'> In Single Application redirecting/reloading is not allowed</h2>
       </div>
  )
}
