import React from "react";
import "../Material_TakeOff/Material_TakeOff.css";

export  const PageNameContaqiner = (props) => {

  return (
    <>
      <div className="PageNameContainer">
        <p> {props.text}</p>
      </div>
    </>
  );
};
